import React from 'react';
import get from 'lodash.get';
import HeroSlider from 'components/HeroSlider';
import { injectIntl } from 'gatsby-plugin-react-intl';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import CustomLink from 'components/Link';
import parse from 'html-react-parser';

import {
    Spacing,
    SharedStyles,
    Container,
    ImageTextGridSingle,
    EmbeddedForm,
} from '../kenra-storybook/index';

const {
    StSectionInfo,
    StSectionTitle,
    StSectionBody,
    StSectionLink,
} = SharedStyles;

const TenYearsLanding = ({ intl, page }) => {
    let sectionData = page.sectionData.fragments.map(section => {
        let returnData = {};
        section.forEach(fragmentData => {
            switch (fragmentData.key) {
                case 'Image':
                    returnData['img'] = fragmentData.value;
                    break;
                case 'Text':
                    returnData['text'] = fragmentData.value;
                    break;
                case 'LinkText':
                    returnData['link'] = fragmentData.value;
                    break;
                case 'LinkUrl':
                    returnData['url'] = fragmentData.value;
                    break;
                default:
                    break;
            }
        });
        return {
            image: returnData.img,
            text: parse(returnData.text),
            link: returnData.link,
            url: returnData.url,
        };
    });

    return (
        <>
            <Spacing removeSpaceTop removeSpaceBottomMobile>
                <HeroSlider slides={page.banner} />
            </Spacing>
            <Spacing removeSpaceTop removeSpaceBottom>
                <Container>
                    <StSectionTitle>
                        {documentToReactComponents(JSON.parse(get(page, 'text.raw') || '{}'))}
                    </StSectionTitle>
                </Container>
            </Spacing>
            <Spacing removeSpaceTop>
                {sectionData && (
                    <ImageTextGridSingle singleRow items={sectionData} />
                )}
            </Spacing>
            <Spacing>
                <Container fullWidth>
                    <img src={get(page, 'logo.localFile.publicURL')} />
                </Container>
            </Spacing>
        </>
    );
};

/*
// REMOVE THE LOGO AND INSTRUCTIONS FROM THE 10 YEARS SWEEPSTAKES
            <Spacing>
                <Container>
                    <img src={get(page, 'logo.localFile.publicURL')} />
                </Container>
            </Spacing>
            <Spacing>
                <Container>
                    <StSectionLink>
                        {documentToReactComponents(
                            get(page, 'instructions.json')
                        )}
                    </StSectionLink>
                </Container>
            </Spacing>
            <Spacing>
                <Container>
                    <EmbeddedForm src={'https://woobox.com/xb3mmg'} />
                </Container>
            </Spacing>
            <Spacing>
                <Container>
                    <StSectionLink>
                        {documentToReactComponents(
                            get(page, 'bottomText.json')
                        )}
                    </StSectionLink>
                </Container>
            </Spacing>

*/
export default injectIntl(TenYearsLanding);
